@media (max-width: 898px) {
  body {
    padding-top: 110px !important
  }
}

#app-container {
  position: relative;
  background-color: var(--sds-color-bg-page)
}

app-container {
  display: flex;
}

app-root {
  margin-top: 64px !important;
  flex: 1;
  position: relative;
}

#main-sidebar {
  z-index: 2;
}